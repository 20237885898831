import(/* webpackMode: "eager", webpackExports: ["AppLogic"] */ "/app/apps/web/app/[locale]/AppLogic.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/App/App.css");
;
import(/* webpackMode: "eager", webpackExports: ["DriftrockScript"] */ "/app/apps/web/src/Components/DriftrockScript/DriftrockScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GptScript"] */ "/app/apps/web/src/Components/GptScript/GptScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtmScript"] */ "/app/apps/web/src/Components/GtmScript/GtmScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnowplowScript"] */ "/app/apps/web/src/Components/SnowplowScript/SnowplowScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdblockChecker"] */ "/app/libs/cmp/src/QuantcastAdblockChecker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuantcastChoiceScript"] */ "/app/libs/cmp/src/QuantcastChoiceScript.tsx");
;
import(/* webpackMode: "eager" */ "/app/libs/cmp/src/QuantcastSsr.css");
;
import(/* webpackMode: "eager" */ "/app/libs/gds/src/Icons/Styles/Default.module.css");
;
import(/* webpackMode: "eager" */ "/app/libs/gds/src/Themes/default.css");
;
import(/* webpackMode: "eager" */ "/app/libs/whitelabel/src/Styles/rac.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/font/local/target.css?{\"path\":\"../../libs/whitelabel/src/Fonts/Objektiv/Objektiv.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"preload\":true,\"variable\":\"--font-family-system\",\"fallback\":[\"Objektiv\",\"sans-serif\"],\"src\":[{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"100\"},{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"400\"},{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"normal\"},{\"path\":\"./objektivmk3_medium.woff2\",\"weight\":\"500\"},{\"path\":\"./objektivmk3_bold.woff2\",\"weight\":\"600 700\"},{\"path\":\"./objektivmk3_xbold.woff2\",\"weight\":\"bold\"}]}],\"variableName\":\"objektiv\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/font/local/target.css?{\"path\":\"../../libs/whitelabel/src/Fonts/FrederickSimms/FrederickSimms.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"preload\":true,\"variable\":\"--font-family-system\",\"fallback\":[\"Objektiv\",\"sans-serif\"],\"src\":[{\"path\":\"./FrederickSimms-Light.woff2\",\"weight\":\"200\"},{\"path\":\"./FrederickSimms-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"./FrederickSimms-Bold.woff2\",\"weight\":\"500\"}]}],\"variableName\":\"FrederickSimms\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.23.5_@playwright+test@1.45.2_react-dom@18.3.0-canary-f359f9b41-2023_4nrst3dllvmkutpwh2xqijtynu/node_modules/next/font/local/target.css?{\"path\":\"../../libs/whitelabel/src/Fonts/DinRoundOt/DinRoundOt.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"preload\":true,\"variable\":\"--font-family-system\",\"fallback\":[\"Objektiv\",\"sans-serif\"],\"src\":[{\"path\":\"./DINRoundOTMedium.woff2\",\"weight\":\"500\"},{\"path\":\"./DINRoundOTRegular.woff2\",\"weight\":\"normal\"}]}],\"variableName\":\"dinRoundOT\"}");
